import http from '@/api/http'
export default {

      async  setAllCompanies({commit}) {
            try {
                  const response = await http.get('company/')
                  const result = await response.data
                  commit('SET_ALL_COMPANY',result.data)
            } catch (error) {
                  console.log(error)
            }
        },

        async addCompany({commit, dispatch}, payload) {
            try {
                const response = await http.post('company/', payload.company)
                const result = await response.data
                commit('ADD_COMPANY', result.data)
                payload.cb()
            } catch (error) {
                const err = error.response.data.name.toString()
                payload.cb()
                dispatch("notification/addNotification", {
                    type: 'error',
                    message: `${err}`
              }, { root: true });
            }
        },

        async updateCompany({commit}, obj) {
            console.log(obj.company);
            try {
                  const response = await http.put(`company/?id=${obj.company.id}`, obj.company)
                  const result = await response.data
                  commit('UPDATE_COMPANY', result.data)
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
      },

        async deleteCompany({commit, dispatch}, payload) {
            try {
               const response = await http.delete(`company/?id=${payload.id}`)
               const result = await response.data
               commit('DELETE_COMPANY', payload.id)
               payload.cb()
            } 
            catch (error) {
                payload.cb()
                dispatch("notification/addNotification", {
                    type: 'error',
                    message: 'You can`t delete this company'
              }, { root: true });   
            }
        }

}