export default {

        SET_ALL_COST(state, payload){
            state.costs = payload
        },

        ADD_COST(state, payload) {
            state.costs.push(payload)
        },

        // DELETE_COUNTRY(state, id) {
        //     const country_id = state.countries.findIndex(elem => elem.id === id)
        //     state.countries.splice(country_id, 1)
        // },

        // UPDATE_COUNTRY(state, payload) {
        //     const edit_id = state.countries.findIndex(elem => elem.id === payload.id)

        //     if (edit_id > -1) {
        //             state.countries[edit_id] = payload       
        //     }
        //     else {
        //         console.log('error country update')
        //     }
           
        // }

}