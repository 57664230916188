export default {

        getAllArchives(state) {
            return state.archives.map((elem) => {
                return {
                    ...elem,
                    'course_sem': `${elem.course} - ${elem?.semester}`
                }
            })
        },

        getStudentPagination(state) {
            return state.pagination
        }

}