let baseURL = ""

if(process.env.NODE_ENV == "production") {
    // baseURL = "http://127.0.0.1:8000"
    baseURL = "https://api.med.frank96.uz"
} else {
    baseURL = "http://127.0.0.1:8000"
}


export default baseURL