<template>
    <div class="notification" v-if="getAllNotification.length">
            <transition-group name="item" tag="div">
                <notification-message v-for="(notification, index) in getAllNotification"
                :key="notification.id"
                :notification="notification"
                />
             </transition-group>
            <!-- <div v-for="(item, index) in getAllNotification" :key="item.id">
                
                              {{item.message}}
            </div>
           -->
     </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import NotificationMessage from './NotificationMessage.vue'
export default {
    components :{
       'notification-message': NotificationMessage
    },
    data() {
        return {
            
        }
    },
    computed:{
        ...mapGetters('notification',['getAllNotification'])
    },

    
}
</script>

<style scoped>
.notification {
    position: fixed;
    top: 150px;
    right: 30px;
    min-width: 250px;
    z-index: 10;
    /* background: rgb(162, 222, 102); */
}



.item-enter-active{
animation: fadeIn 0.5s;
}

.item-leave-active{
animation: fadeOut 1.0s;
}

@keyframes fadeIn{
from{opacity: 0}
to{opacity: 1}
}

@keyframes fadeOut{
from{opacity: 1}
to{opacity: 0}
}
</style>