import { createApp } from "vue";
import App from "./App.vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import "./assets/styles/main.css";
import router from "@/router";
import store from "@/store";
import i18n from './i18n'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faHouse,
  faUsers,
  faBuildingColumns,
  faFlag,
  faClone,
  faFileLines,
  faFileExcel,
  faMoneyCheckDollar,
  faGraduationCap,
  faHandshake,
  faPaperclip,
  faHandHoldingDollar,
  faFileInvoiceDollar,
  faBoxArchive
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHouse,
  faUsers,
  faBuildingColumns,
  faFlag,
  faClone,
  faFileLines,
  faFileExcel,
  faMoneyCheckDollar,
  faGraduationCap,
  faHandshake,
  faPaperclip,
  faHandHoldingDollar,
  faFileInvoiceDollar,
  faBoxArchive
);

{/* <i class="fa-solid fa-box-archive"></i> */}


const app = createApp(App);

app.use(Quasar, quasarUserOptions);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(i18n);
app.mount("#app");
