export default {

        SET_ALL_FACULTY(state, payload){
            state.faculties = payload
            
        },

        ADD_FACULTY(state, payload) {
            state.faculties.push(payload)
        },

        DELETE_FACULTY(state, id) {
            const faculty_id = state.faculties.findIndex(elem => elem.id === id)
            state.faculties.splice(faculty_id, 1)
        },

        
        UPDATE_FACULTY(state, payload) {
            const edit_id = state.faculties.findIndex(elem => elem.id === payload.id)

            if (edit_id > -1) {
                    state.faculties[edit_id] = payload       
            }
            else {
                console.log('error faculty update')
            }
           
        }

}