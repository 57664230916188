import http from '@/api/http'
export default {

      async  setAllStatuses({commit}) {
            try {
                  let response = await http.get('student-status/')
                  let result = await response.data
                  commit('SET_ALL_STATUS',result.data)
            } catch (error) {
                  console.log(error)
            }           
        },

      async addStatus({commit, dispatch}, payload) {
      try {
            const response = await http.post('student-status/', payload.status)
            const result = await response.data
            commit('ADD_STATUS', result.data)
            payload.cb()
      } catch (error) {
            const err = error.response?.data.name.toString()
            payload.cb()
            dispatch("notification/addNotification", {
                  type: 'error',
                  message: `${err}`
            }, { root: true });
         
      }
      },  

      async updateStatus({commit}, obj) {
            console.log(obj);
            try {
                  const response = await http.put(`student-status/?id=${obj.status.id}`, obj.status)
                  const result = await response.data
            
                  commit('UPDATE_STATUS', result.data)
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
      },
      
      async deleteStatus({commit,dispatch}, payload) {
            try {
               const response = await http.delete(`student-status/?id=${payload.id}`)
               const result = await response.data
               commit('DELETE_STATUS', payload.id)
               payload.cb()
            } 
            catch (error) {
                  console.log(error)
                  // reject(error.response.data.errors)
                  payload.cb()
                  dispatch("notification/addNotification", {
                        type: 'error',
                        message: 'You can`t delete this status'
                  }, { root: true });
            }
        }

}