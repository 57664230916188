<template>

      <router-view/>
</template>

<script>


export default {
  name: 'LayoutDefault',
  
  methods: {
    lang() {
      const language = localStorage.getItem('lang')
      const lg  = ["ru", "en"]
      if (lg.includes(language)) {
      }
      else {
        localStorage.setItem('lang', 'ru')
      }
    }
  },

  created() {
    // console.log('created');
      // this.lang()
  },

}
</script>
