import http from "@/api/http";
export default {

  async setAllArchives({ commit }, payload) {
    try {
      const response = await http.get(`student-archive`, {

        params: {
          ...payload,
        },
      });
      const result = await response.data;
      commit("SET_ALL_ARCHIVES", result.data);
      commit("SET_PAGINATION", result.paginate)
    } catch (error) {
      console.log(error);
    }
  },


  async send_archive({commit},obj) {
    try {
      const response = await http.put('student-archive',{
        archive:obj.archive })
      const result = await response.data
      // commit('SEND_TO_ARCHIVE', result.data)
      commit('student/SEND_TO_ARCHIVE', result.data, { root: true })
      obj.cb()
    }
    catch (error) {
      console.log(error);
    }

  }
};
