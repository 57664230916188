export default {

        getAllStudents(state) {
            return state.students.map((elem) => {
                return {
                    ...elem,
                    'course_sem': `${elem.course} - ${elem?.semester}`
                }
            })
        },
        
        getAllCategoryFilter(state) {
            return state.categoryFilter
        },

        getStudentPagination(state) {
            return state.pagination
        }

}