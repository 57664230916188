export default {

        SET_ALL_COMPANY(state, payload){
            state.companies = payload
        },

        ADD_COMPANY(state, payload) {
            state.companies.push(payload)
        },

        DELETE_COMPANY(state, id) {
            const country_id = state.companies.findIndex(elem => elem.id === id)
            state.companies.splice(country_id, 1)
        },

        UPDATE_COMPANY(state, payload) {
            const edit_id = state.companies.findIndex(elem => elem.id === payload.id)

            if (edit_id > -1) {
                    state.companies[edit_id] = payload       
            }
            else {
                console.log('error companies update')
            }
           
        }

}