export default {

        getAllPayments(state) {
            return state.payments
        },

        getPaymentPagination(state) {
            return state.pagination
        }
      

}