import http from '@/api/http'
export default {

      async  setAllContractPrice({commit}) {
            try {
                  const response = await http.get('contract-cost/')
                  const result = await response.data
                  commit('SET_ALL_COST',result.data)
                  console.log(result)
            } catch (error) {
                  console.log(error)
            }
        },

        async addCost({commit, dispatch}, payload) {
            try {
                const response = await http.post('contract-cost/', payload.price)
                const result = await response.data
                commit('ADD_COST', result.data)
                console.log(result.data)
                payload.cb()
            } catch (error) {
                const err = error.response?.data.error.faculty.toString()
                console.log(err);
                payload.cb()
                dispatch("notification/addNotification", {
                    type: 'error',
                    message: `${err}`
              }, { root: true });
            }
        },

    //     async updateCountry({commit}, obj) {
    //         try {
    //               const response = await http.put(`country/?id=${obj.country.id}`, obj.country)
    //               const result = await response.data
    //               commit('UPDATE_COUNTRY', result.data)
    //               console.log(result.data, 'result')
    //               obj.cb()
    //         } catch (error) {
    //               console.log(error)
    //         }
    //   },

        // async deleteCountry({commit}, payload) {
        //     try {
        //        const response = await http.delete(`country/?id=${payload.id}`)
        //        const result = await response.data
        //        commit('DELETE_COUNTRY', payload.id)
        //        payload.cb()
        //     } 
        //     catch (error) {
        //         console.log(error)
    
        //     }
        // }

}