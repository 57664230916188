export default {

        getAllOrders(state) {
            return state.orders
        },
       
        getVisaEnd(state) {
            if(state.orders){
                let elems = state.orders.filter(elem => elem.visa_diff <= 15) 

                return elems.length
            }
            return  0
        },

        getAllNotifications(state) {
            return state.notifications
        },

        getOrderDocPagination(state) {
            return state.pagination
        }

}