import axios from "axios";
import baseURL from "@/api/server_url"
import jwt_decode from "jwt-decode";
class AuthServices {
    login(user) {
         return axios.post(`${baseURL}/api/login/`, {
            username: user.username,
            password: user.password
         })
         .then( response => {
            if (response) {
                localStorage.setItem("accessToken", response.data.access);
                localStorage.setItem("refreshToken", response.data.refresh);
                
                let jwt = jwt_decode(response.data.access)
                localStorage.setItem('role', jwt.role)
                try {
                    // auth.dispatch("auth/loginSuccess")  
                    // console.log(store.dispatch('auth/loginSuccess', response.data.username),' store')
                    
                } catch (error) {
                    console.log(error, 'error')
                }
            }
            return response 
        }).catch((reason) => {
            console.log('password or login is not valid')
        })    
    }
    logout() {
        localStorage.removeItem('user')
    }
}


export default new AuthServices()