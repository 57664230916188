<template>
   <q-header elevated class="bg-amber-6">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="openSidebar"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title>
            {{ $t("single.title") }} 
            <q-btn :color="$i18n.locale == 'en' ? 'white' : 'primary' "  @click="showLocal('ru')" text-color="black" dense label="ru"/>
            <q-btn :color="$i18n.locale == 'ru' ? 'white' : 'primary' " color="white" @click="showLocal('en')" text-color="black" class="q-ml-xs" dense label="en"/>
        </q-toolbar-title> 
        <div>
            <notify-header v-if="getMyName != 'account'" />
        </div>
        <div class="admin_btn"> 
                
              <q-menu anchor="bottom left" self="top left">
                <q-item clickable>
                    <q-item-section>Settings</q-item-section>
                </q-item>
                <q-item clickable @click="onLogout">
                    <q-item-section>Logout</q-item-section>
                </q-item>
            </q-menu>
            <div class="admin_title">
                {{getMyName }}
            </div>
      </div>
      </q-toolbar>
    </q-header>
</template>

<script>
import NotifyHeader from './NotifyHeader.vue'
export default {
    components: {
        'notify-header': NotifyHeader
    },
    emits:['openleft'],
    data() {
        return {
            lang: "ru"
        }
    },
    methods: {
        showLocal(lang) {
           this.$i18n.locale = lang
           localStorage.setItem('lang', lang)
        },
        openSidebar() {
            this.$emit('openleft') 
        },

        onLogout() {
            this.$router.push('login')
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('role');
        }
    },
    computed:{
        getMyName() {
            let role = localStorage.getItem('role')
            return role ? role : 'default'
        }
    },
}
</script>

<style  scoped>
.admin_btn{
    display: flex;
    cursor: pointer;
    margin-right: 15px;
}

</style>