export default {
    PUSH_NOTIFICATION(state, notification){
      state.notifications.push({
        ...notification,
        id: Date.now().toLocaleString()
      })
  },

  REMOVE_NOTIFICATION(state, { id }){
        state.notifications = state.notifications.filter(msg => msg.id !== id);
  }
}