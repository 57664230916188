export default {
    
      addNotification({commit}, notification) {
            commit('PUSH_NOTIFICATION',notification)

            // setTimeout(() => {

            // },2000)
      },

      removeNotification({commit}, notification) {
            // console.log(notification, 'ssss 2222')
            commit('REMOVE_NOTIFICATION',notification)
      }
}