export default {

        SET_PAGINATION(state, payload) {
            state.pagination = payload
        },
        
        SET_ALL_STUDENTS(state, payload){
            state.students = payload
        },

        ADD_STUDENT(state, payload) {
            console.log(payload, 'payload');
            state.students.unshift(payload)
        },
        
        UPDATE_STUDENT(state, payload) {
            const edit_id = state.students.findIndex(elem => elem.id === payload.id)
            if (edit_id > -1) {
                    state.students[edit_id] = payload       
            }
            else {
                console.log('error student update')
            }
        },

        UPDATE_STUDENT_IMAGES(state, payload) {
            console.log(payload);
            const edit_id = state.students.findIndex(elem => elem.id === payload.id)
            console.log(edit_id),' edit';
            if (edit_id > -1) {
                    state.students[edit_id].student_photo = payload.student_photo      
            }
            else {
                console.log('error student update')
            }
        },

        DELETE_STUDENT(state, id) {
            const student_id = state.students.findIndex(elem => elem.id === id)
            state.students.splice(student_id, 1)
        },

        SEND_TO_ARCHIVE(state, obj) {
          state.students=state.students.filter((elem => {
                return !obj.includes(elem.id)
            })
            )
        }

}