export default {

        SET_ALL_COUNTRY(state, payload){
            state.countries = payload
        },

        ADD_COUNTRY(state, payload) {
            state.countries.push(payload)
        },

        DELETE_COUNTRY(state, id) {
            const country_id = state.countries.findIndex(elem => elem.id === id)
            state.countries.splice(country_id, 1)
        },

        UPDATE_COUNTRY(state, payload) {
            const edit_id = state.countries.findIndex(elem => elem.id === payload.id)

            if (edit_id > -1) {
                    state.countries[edit_id] = payload       
            }
            else {
                console.log('error country update')
            }
           
        }

}