export default {

        getAllСontracts(state) {
            return state.contracts
        },
        
        getContractPagination(state) {
            return state.pagination
        }

}