export default {

        SET_ORDER(state, payload){
            state.orders =(payload)
        },

        UPDATE_ORDER(state, payload) {
            console.log(payload)
            const order_id = state.orders.findIndex(elem => elem.id === payload.id)

            if (order_id > -1) {
             
                state.orders[order_id].visa_end  = payload.visa_end   
                state.orders[order_id].visa_diff  = payload.visa_diff
    
                state.orders[order_id].register_end  = payload.register_end   
                state.orders[order_id].register_diff  = payload.register_diff
    
                state.orders[order_id].medical_insurance_end  = payload.medical_insurance_end   
                state.orders[order_id].medical_insurance_diff  = payload.medical_insurance_diff
    
                state.orders[order_id].passport_end_date  = payload.passport_end_date   
                state.orders[order_id].passport_diff  = payload.passport_diff
                console.log('godddd')
            }
           

            else {
                console.log('error update')
            }
           
        },

        SET_NOTIFY_ORDER(state, payload) {
            state.notifications = payload
        },

        SET_PAGINATION(state, payload) {
            state.pagination = payload
        },

}