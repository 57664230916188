import http from '@/api/http'
export default {

      async  setAllСustomers({commit}) {
            try {
                  const response = await http.get('customer/')
                  const result = await response.data
                  commit('SET_ALL_CUSTOMERS',result.data)
                  console.log(result.data, 'customer')
            } catch (error) {
                  console.log(error)
            }
        },

}