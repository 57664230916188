<template>
    <div :class="notification.type === 'error' ? 'notify_error' : 'notify_good' " class="content">
        {{notification.message}}
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
    
    props:{
        notification: {
            type: Object,
            
        }
    },
    data() {
        return {
            timeout: null
        }
    },
     methods: {
        ...mapActions('notification',['removeNotification'])
    },
    created() {
        this.timeout = setTimeout(() => {
            this.removeNotification(this.notification)
        },4000)
    },

    // beforeDestroy() {
    //     clearTimeout(this.timeout)
    // }

    beforeUnmount() {
        clearTimeout(this.timeout)
        
    }

}
</script>

<style scoped>
.content{
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    color: #FFF;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, .6);
    text-align: center;
}
.notify_error {
    background: rgb(238, 101, 64);
}
.notify_good {
    background: rgb(131, 224, 60);
}
</style>