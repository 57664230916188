import http from '@/api/http'
export default {

      async setAllOrders({commit,state},obj) {
                  
            let filter_params = {}
            state.excel_datas = filter_params
            // console.log(obj, 'obkeee');
            for (let key in obj) {
                  if (obj[key] === null) {
                    delete obj[key];
                  }
                }
                console.log(obj, 'last answer');
            // if(obj){
            //       let obj_keys = Object.keys(obj)

            //       if ( (obj_keys.length == 1) && (obj_keys == 'type_document') ) {
            //             filter_params.type_document = obj.type_document
            //       }
            //       else if ( obj_keys.length == 3 ) {
            //             filter_params.type_document = obj.type_document
            //             filter_params.date_start = obj.date_start 
            //             filter_params.date_end = obj.date_end 
            //       }
            //       else if ( obj_keys == 'student_name' ) {
            //             if (obj.student_name != null) {
            //                   filter_params.student_name =  obj.student_name     
            //             }
            //       }
            //       else if (obj_keys.length == 1 && obj_keys == 'company' ) {
            //                   filter_params.company = obj.company     
            //       }
            //       else if (obj_keys == 'page') {
            //             filter_params.p = obj.p
            //       }
            //       else {
            //             filter_params = {}
            //       }           
            // }

            try {
                  let response = await http.get('order-document/',{
                        params: obj
                  })
                  let result = await response.data
                  commit('SET_ORDER',result.data)  
                  commit("SET_PAGINATION", result.paginate)
            } catch (error) {
                        console.log(error)
            }
           
        },
      
      async updateOrder({commit,dispatch }, obj) {
          
            try {
                  const response = await http.put(`order-document/?id=${obj.order.id}`, obj.order)
                  const result = await response.data
                  commit('UPDATE_ORDER', result.data)
                  dispatch('setLastDays')
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
          
      },
      async setLastDays({commit}) {
            try {
                  const response = await http.get(`order-notify/`)
                  const result = await response.data
                  commit('SET_NOTIFY_ORDER', result.data)
            } catch (error) {
                  console.log(error)
            }
      },
        exportExcel({state}, data) {
            console.log(data.company);
            let filter_params = state.excel_datas
            http.get('export_excel/',{
                  responseType: 'blob',
                  params: {...filter_params, company: data.company}
              }).then((response) => {
                   let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   let fileLink = document.createElement('a');
 
                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', 'file.xlsx');
                   document.body.appendChild(fileLink);
 
                   fileLink.click();
              }).catch((error) => {
                  console.log(error)
              });
        }

}