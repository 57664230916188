import http from '@/api/http'
export default {

      async  setAllFaculties({commit}) {
            try {
                  let response = await http.get('faculty/')
                  let result = await response.data
                  commit('SET_ALL_FACULTY',result.data)
            } catch (error) {
                  console.log(error)
            }           
        },

      async addFaculty({commit, dispatch}, payload) {
      try {
            const response = await http.post('faculty/', payload.faculty)
            const result = await response.data
            commit('ADD_FACULTY', result.data)
            payload.cb()
          

      } catch (error) {
            const err = error.response?.data.name.toString()
            console.log(error,'error')
            payload.cb()
            dispatch("notification/addNotification", {
                  type: 'error',
                  message: `${err}`
            }, { root: true });
         
      }
      },  

      async updateFaculty({commit}, obj) {
            try {
                  const response = await http.put(`faculty/?id=${obj.faculty.id}`, obj.faculty)
                  const result = await response.data
                  commit('UPDATE_FACULTY', result.data)
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
      },
      
      async deleteFaculty({commit,dispatch}, payload) {
            try {
               const response = await http.delete(`faculty/?id=${payload.id}`)
               const result = await response.data
               commit('DELETE_FACULTY', payload.id)
               payload.cb()
            } 
            catch (error) {
                  console.log(error)
                  // reject(error.response.data.errors)
                  payload.cb()
                  dispatch("notification/addNotification", {
                        type: 'error',
                        message: 'You can`t delete this faculty'
                  }, { root: true });
            }
        }

}