import http from '@/api/http'
export default {

      async  setAllPayments({commit},payload) {
            try {
                  const response = await http.get('payment/' , {
                        params: {
                              ...payload
                        } 
                  })
                  const result = await response.data
                  commit('SET_ALL_PAYMENTS',result.data)
                  commit("SET_PAGINATION", result.paginate)
            } catch (error) {
                  console.log(error)
            }
        },

        async addPayment({commit,dispatch}, payload) {
            console.log(payload, 'payload 1111')
            try {
                const response = await http.post('payment/', payload.payment)
                const result = await response.data
                commit('ADD_PAYMENT', result.data)
                commit("contract/UPDATE_PRICE", {
                            payment: payload.payment
                      }, { root: true });
                payload.cb()
            } catch (error) {
                  console.log(error,'eee')
            //     const err = error.response.data?.data.student
            //     payload.cb()
            //     dispatch("notification/addNotification", {
            //           type: 'error',
            //           message: `${err.name}`
            //     }, { root: true });
            }
        },

   

}