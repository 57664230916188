import http from '@/api/http'
export default {

      async  setAllСountries({commit}) {
            try {
                  const response = await http.get('country/')
                  const result = await response.data
                  commit('SET_ALL_COUNTRY',result.data)
            } catch (error) {
                  console.log(error)
            }
        },

        async addCountry({commit, dispatch}, payload) {
            try {
                const response = await http.post('country/', payload.country)
                const result = await response.data
                commit('ADD_COUNTRY', result.data)
                payload.cb()
            } catch (error) {
                const err = error.response.data.error?.name.toString()
                payload.cb()
                dispatch("notification/addNotification", {
                    type: 'error',
                    message: `${err}`
              }, { root: true });
            }
        },

        async updateCountry({commit}, obj) {
            try {
                  const response = await http.put(`country/?id=${obj.country.id}`, obj.country)
                  const result = await response.data
                  commit('UPDATE_COUNTRY', result.data)
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
      },

        async deleteCountry({commit, dispatch}, payload) {
            try {
               const response = await http.delete(`country/?id=${payload.id}`)
               const result = await response.data
               commit('DELETE_COUNTRY', payload.id)
               payload.cb()
            } 
            catch (error) {
                payload.cb()
                dispatch("notification/addNotification", {
                    type: 'error',
                    message: 'You can`t delete this country'
              }, { root: true });
    
            }
        }

}