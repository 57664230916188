export default {

        SET_ALL_STUDENT_TYPES(state, payload){
            state.student_types = payload
        },

        ADD_STUDENT_TYPE(state, payload) {
            state.student_types.push(payload)
        },

        DELETE_STUDENT_TYPE(state, id) {
            const type_id = state.student_types.findIndex(elem => elem.id === id)
            state.student_types.splice(type_id, 1)
        },

        UPDATE_STUDENT_TYPE(state, payload) {
            const edit_id = state.student_types.findIndex(elem => elem.id === payload.id)

            if (edit_id > -1) {
                    state.student_types[edit_id] = payload       
            }
            else {
                console.log('error faculty update')
            }
           
        }
}