export default {

        SET_ALL_CONTRACTS(state, payload){
            state.contracts = payload
        },

        ADD_CONTRACT(state, payload) {
            state.contracts.push(payload)
        },
        
        SET_PAGINATION(state, payload) {
            state.pagination = payload
        },
        
        UPDATE_PRICE(state, {payment}) {          
            const payment_id =  state.contracts.findIndex(elem => elem.id == payment.contract)
            if (payment_id > -1) {
                state.contracts[payment_id].debt = +state.contracts[payment_id].debt + parseFloat(payment.price)
                state.contracts[payment_id].current_balance = +state.contracts[payment_id].current_balance + parseFloat(payment.price)          
            }
            
            else {
                console.log('error')
            }
        }

        // UPDATE_COUNTRY(state, payload) {
        //     const edit_id = state.countries.findIndex(elem => elem.id === payload.id)

        //     if (edit_id > -1) {
        //             state.countries[edit_id] = payload       
        //     }
        //     else {
        //         console.log('error country update')
        //     }
           
        // }

}