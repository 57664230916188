import http from "@/api/http";
export default {
  async setAllStudents({ commit }, payload) {
    try {
      const response = await http.get(`student/`, {

        params: {
          ...payload,
        },
      });
      const result = await response.data;
      commit("SET_ALL_STUDENTS", result.data);
      commit("SET_PAGINATION", result.paginate)
    } catch (error) {
      console.log(error);
    }
  },
  async addStudent({ commit, dispatch}, payload) {
    try {
      const response = await http.post("student/", payload.student, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const result = await response.data;
      commit("ADD_STUDENT", result.data);
      payload.cb();
    } catch (error) {
      const err = error.response?.data.errors[0].student
            payload.cb()
            dispatch("notification/addNotification", {
                  type: 'error',
                  message: `${err}`
            }, { root: true });
    }
  },

  async categoryFilter({commit}) {
    try {
      const response = await http.get("category-filter/");
      const result = await response.data;
      const {faculty, country, type_student, status, user, company} = result.data
      commit('faculty/SET_ALL_FACULTY', faculty, { root: true })
      commit('country/SET_ALL_COUNTRY', country, { root: true })
      commit('student_type/SET_ALL_STUDENT_TYPES', type_student, { root: true })
      commit('custom_user/SET_ALL_CUSTOMERS', user, { root: true })
      commit('status/SET_ALL_STATUS', status, { root: true })
      commit('company/SET_ALL_COMPANY', company, { root: true })
    } catch (error) {
      console.log(error);
    }
  },


  async uploadImage({commit, dispatch}, obj) {
    
    try {
      const response = await http.put(`student/change_picture/?id=${obj.id}`, obj.data, 
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const result = await response.data
    commit("UPDATE_STUDENT_IMAGES", result.data);
    console.log(result.data, 'result');
    }
    catch (error) {
      console.log(error, 'eeror');
      const message = error.response.data.errors
      console.log(message.length);
      [1,2].forEach((elem) => {
        dispatch("notification/addNotification", {
          type: 'error',
          message: `message ${elem}`
    }, { root: true });
      })

    }
    

  },

  async updateStudent({ commit }, obj) {
    try {
      const response = await http.put(
        `student/?id=${obj.student.id}`,
        obj.student
      );
      delete obj.student.id
      const result = await response.data;
      commit("UPDATE_STUDENT", result.data);
      obj.cb();
    } catch (error) {
      console.log(error);
    }
  },

  download_file({ _ }, payload) {
    http
      .get("download_document/", {
        responseType: "blob",
        params: {
          id: payload.id,
          path: payload.path,
        },
      })
      .then((response) => {
        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1;
        let yy = today.getFullYear();
        let my_date = `${dd}_${mm}_${yy}`;
        let total_name = `${payload.name}_${payload.file_name}_${my_date}`;
        let file_type = response.data["type"].split("/").pop();
        console.log(file_type, "response");
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fURL = document.createElement("a");
        fURL.href = fileURL;
        fURL.setAttribute("download", `${total_name}.${file_type}`);
        document.body.appendChild(fURL);

        fURL.click();
      })
      .catch((error) => {
        console.log(error, "error");
      });
  },

  exportStudentExcel({ state }, obj) {
    http
      .get("export_student_excel/", {
        responseType: "blob",
        params: obj.student,
      })
      .then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async send_archive({commit},obj) {
    // console.log(obj.archive);
    const response = await http.put('student-archive',{
      archive:obj.archive })
    const result = await response.data
    commit('SEND_TO_ARCHIVE', result.data)
    console.log(result, 'result');
  }
};
