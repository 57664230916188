import {createStore} from 'vuex'
import custom_user from './modules/custom_user/'
import student from './modules/student/'
import faculty from './modules/faculty/'
import status from './modules/status/'
import country from './modules/country/'
import company from './modules/company/'
import order_document from './modules/order_document/index'
import student_type from './modules/student_type'
import auth from './modules/auth'
import contract from './modules/contract'
import contract_price from './modules/contract-price'
import payment from './modules/payment'
import notification from './modules/notification'
import archive from './modules/archive'
const store = createStore({
    modules:{
        auth,
        custom_user,
        student,
        faculty,
        country,
        company,
        status,
        order_document,
        student_type,
        contract,
        contract_price,
        payment,
        notification,
        archive
        
    }
})

export default store
