import en from './locales/en.json'
import ru from './locales/ru.json'
import { createI18n } from "vue-i18n";

export const defaultLocal = () => {
    const lang = localStorage.getItem('lang')
    let ln = "ru"
    const lg  = ["ru", "en"]
    if (lg.includes(lang)) {
    ln = lang
    }
    else {
      localStorage.setItem('lang', 'ru')
    }
    return ln
}

export const language = {
    en,
    ru
}

const i18n = createI18n({
    locale: defaultLocal(),

    messages: {
    en,
    ru
      // ru: {
      //   title: {
      //       home: "Главная",
      //       student: "Студенты",
      //       contract: "Контракты",
      //       price: "Стоимость контрактов",
      //       payment: "Оплаты",
      //       faculty: "Факультеты",
      //       country: "Страны",
      //       program: "Программа",
      //       company: "Компании",
      //       status: "Статусы",
      //       document: "Документы"
      //     },
      // },
    },
  });


  export default i18n