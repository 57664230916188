<template>
      <q-drawer
        show-if-above
        :mini="miniState"
        @mouseover="miniState = false"
        @mouseout="miniState = true"
        mini-to-overlay
        :width="250"
        :breakpoint="800"
        bordered>
        <q-scroll-area class="fit" :horizontal-thumb-style="{ opacity: 0 }">
          <q-list>
      <router-link
        v-for="(item, index) in menu"
        :key="index"
        :to="{ name: item.name }"
        >
        <template v-if="item.role.includes(take_role)">
          <q-item>
            <q-item-section avatar>
              <font-awesome-icon
                class="icon_style"
                :icon="'fa-solid ' + item.icon"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label> {{ item.title }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </router-link>
      <router-link :to="{ name: 'order-document' }"  v-if="take_role != 'account'">
        <q-item>
          <q-item-section avatar>
            <font-awesome-icon
              class="icon_style"
              icon="fa-solid fa-file-lines"
            />
          </q-item-section>
          <q-item-section>
            <div
              class="fit row wrap justify-between items-center content-center">
              <div>
                <q-item-label>{{ $t('title.document') }}</q-item-label>
              </div>
            </div>
          </q-item-section>
        </q-item>

      </router-link>
            <router-link :to="{ name: 'archive' }" v-if="take_role != 'account'">
        <q-item>
          <q-item-section avatar>
            <font-awesome-icon
              class="icon_style"
              icon="fa-solid fa-box-archive"
            />
          </q-item-section>
          <q-item-section>
            <div
              class="fit row wrap justify-between items-center content-center">
              <div>
                <q-item-label>{{ $t('title.archive') }}</q-item-label>
              </div>
            </div>
          </q-item-section>
        </q-item>

      </router-link>

    </q-list>
        </q-scroll-area>
      </q-drawer>
</template>

<script>
export default {
  props: ["leftDrawerOpen"],

  data() {
    return {
      drawer: false,
      miniState: true
    };
  },
  computed: {
    take_role() {  
      return localStorage.getItem("role");
    },
    menu() {
      return [
        {
          name: "home",
          icon: "fa-house",
          title: this.$t('title.home'),
          role: ["admin", "national"],
        },
        {
          name: "student",
          icon: "fa-users",
          title: this.$t('title.student'),
          role: ["admin", "national"],
        },
        {
          name: "contract",
          icon: "fa-money-check-dollar",
          title: this.$t('title.contract'),
          role: ["admin", "account"],
        },
        {
          name: "contract-price",
          icon: "fa-file-invoice-dollar",
          title: this.$t('title.price'),
          role: ["admin", "account"],
        },
        {
          name: "payment",
          icon: "fa-hand-holding-dollar",
          title: this.$t('title.payment'),
          role: ["admin", "account"],
        },
        {
          name: "faculty",
          icon: "fa-building-columns",
          title: this.$t('title.faculty'),
          role: ["admin", "national"],
        },
        {
          name: "country",
          icon: "fa-flag",
          title: this.$t('title.country'),
          role: ["admin", "national"],
        },
        {
          name: "program",
          icon: "fa-graduation-cap",
          title: this.$t('title.program'),
          role: ["admin", "national"],
        },
         {
          name: "company",
          icon: "fa-handshake",
          title: this.$t('title.company'),
          role: ["admin", "national"],
        },
         {
          name: "status",
          icon: "fa-paperclip",
          title: this.$t('title.status'),
          role: ["admin", "national"],
        },
      ];
    },
  },
};
</script>

<style scoped>
a:link {
  text-decoration: none;
  color: #000;
}

a.router-link-active .q-item {
  background: rgb(157, 171, 186);
  color: purple;
}

a:hover .q-item {
  background: rgb(199, 209, 219);
}
.icon_style {
  color: rgb(24, 49, 83);
  width: 24px;
  height: 24px;
}
</style>
