export default {

        getAllCompanies(state) {

            return state.companies.map((elem, idx) => {
                return {
                    ...elem,
                }
            })
        },
      

}