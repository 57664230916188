export default {

        SET_ALL_STATUS(state, payload){
            state.statuses = payload
            
        },

        ADD_STATUS(state, payload) {
            state.statuses.push(payload)
        },

        DELETE_STATUS(state, id) {
            const delete_id = state.statuses.findIndex(elem => elem.id === id)
            state.statuses.splice(delete_id, 1)
        },

        
        UPDATE_STATUS(state, payload) {
            const edit_id = state.statuses.findIndex(elem => elem.id === payload.id)

            if (edit_id > -1) {
                    state.statuses[edit_id] = payload       
            }
            else {
                console.log('error faculty update')
            }
           
        }

}