import {createRouter, createWebHistory} from 'vue-router'
import MainApp from '@/views/MainApp'
const routes = [
    {
        path:'/',
        component: MainApp,
        meta:{auth:true},
        children:
        [
            {
                path :'home',
                alias:'/',
                name:'home',
                component: () => import('@/views/home/Home.vue'),
                meta:{
                    auth: true
                }
            },
            {
                path: 'student',
                name: 'student',
                component: () => import('@/views/student/Student.vue'),
                meta:{auth:true},
            },

            {
                path: 'faculty',
                name: 'faculty',
                component: () => import('@/views/faculty/Faculty.vue'),
                meta:{auth:true},
            },
            {
                path: 'country',
                name: 'country',
                component: () => import('@/views/country/Country.vue'),
                meta:{auth:true},
            },
            {
                path: 'program',
                name: 'program',
                component: () => import('@/views/program/Program.vue'),
                meta:{auth:true},
            },
            {
                path: 'payment',
                name: 'payment',
                component: () => import('@/views/payment/Payment.vue'),
                meta:{auth:true},
            },
            {
                path: 'company',
                name: 'company',
                component: () => import('@/views/company/Company.vue'),
                meta:{auth:true},
            },
            {
                path: 'status',
                name: 'status',
                component: () => import('@/views/status/Status.vue'),
                meta:{auth:true},
            },
            {
                path: 'order-document',
                name: 'order-document',
                component: () => import('@/views/order_document/OrderDocument.vue'),
                meta:{auth:true},
            },
            {
                path: 'contract',
                name: 'contract',
                component: () => import('@/views/contract/Contract.vue'),
                meta:{auth:true},
            }, 
            {
                path: 'contract-price',
                name: 'contract-price',
                component: () => import('@/views/contract-price/ContractPrice.vue'),
                meta:{auth:true},
            },
            {
                path: 'archive',
                name: 'archive',
                component: () => import('@/views/archive/Archive.vue'),
                meta:{auth:true},
            },

            
        ]
    },
    {
        path:'/login',
        name:'login',
        component: () => import('@/views/login/Login')
    },

    {
        path: '/:any(.*)',
        component: () => import('@/views/E404/E404.vue') 
    }
]

const router = createRouter({
    routes,
    history: createWebHistory()
})


router.beforeEach((to, from, next) => {

    const requireAuth = to.matched.some(record => record.meta.auth)
    const accessToken = localStorage.getItem('accessToken');
    // const publicPages = ['/login'];
    // const authRequired = !publicPages.includes(to.path);

    // console.log(requireAuth, 'auth')
    // if (requireAuth && !accessToken) {
    //     next('/login')
    // }

    // next()

    if (!accessToken && to.path !== '/login') {
        next('/login')
       return
      }
      else if (accessToken && to.path === '/login') {
        next(from.path)
       return
      } 
      next()

})

export default router