<template>
     <q-layout view="lHh Lpr lFf">

  <header-vue  @openleft="openHandle"/> 
    <sidebar :leftDrawerOpen="leftDrawerOpen" />
     <q-page-container>    
      <router-view />
       </q-page-container>
      <notification />
  </q-layout>
</template>

<script>
import HeaderVue from '@/views/layout/Header'
import Sidebar from '@/views/layout/Sidebar'
import Notification from '@/components/notifications/Notification.vue'
export default {
    components:{
        'header-vue': HeaderVue,
        'sidebar': Sidebar,
         'notification': Notification
    },

  data() {
    return {
      leftDrawerOpen: true,
    };
  },

  methods: {
      openHandle() {
          this.leftDrawerOpen = !this.leftDrawerOpen
          console.log(this.leftDrawerOpen)
          console.log('open')
      }
  },
}
</script>

<style>

</style>