import http from '@/api/http'
export default {

      async  setAllContracts({commit}, payload) {
            try {
                  const response = await http.get('contract/', {
                    params: {
                        ...payload
                    }
                  })
                  const result = await response.data
                  commit('SET_ALL_CONTRACTS',result.data)
                  commit("SET_PAGINATION", result.paginate)
            } catch (error) {
                  console.log(error)
            }
        },

        async addContract({commit,dispatch}, payload) {
            try {
                const response = await http.post('contract/', payload.contract)
                const result = await response.data
                commit('ADD_CONTRACT', result.data)
                payload.cb()
            } catch (error) {
                const err = error.response.data.errors[0]
                console.log(err);
                payload.cb()
                dispatch("notification/addNotification", {
                      type: 'error',
                      message: `${err.cost}`
                }, { root: true });
            }
        },

    //     async updateCountry({commit}, obj) {
    //         try {
    //               const response = await http.put(`country/?id=${obj.country.id}`, obj.country)
    //               const result = await response.data
    //               commit('UPDATE_COUNTRY', result.data)
    //               console.log(result.data, 'result')
    //               obj.cb()
    //         } catch (error) {
    //               console.log(error)
    //         }
    //   },

    //     async deleteCountry({commit}, payload) {
    //         try {
    //            const response = await http.delete(`country/?id=${payload.id}`)
    //            const result = await response.data
    //            commit('DELETE_COUNTRY', payload.id)
    //            payload.cb()
    //         } 
    //         catch (error) {
    //             console.log(error)
    
    //         }
    //     }

}