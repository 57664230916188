import http from '@/api/http'
import AuthService from '@/services/AuthService'
import router from '@/router'
export default {

        loginSuccess({commit}, payload) {
             console.log(`payload ${payload}`)

             commit('SET_USER', payload)
        }, 
    
        loginUser(_, payload) {

            AuthService.login(payload).then((result) => {
                const role = localStorage.getItem('role')
                role !== 'account' ? router.push("home") : router.push("contract");
                // if
                // console.log( role != 'account');
                // this.$router.push({
                //     name: 'home'
                //   })
            }).catch((err) => {
                console.log(err)
            });
        },


        async logout({commit}, payload) {
           
        }

}