export default {

        SET_USER(state, payload) {
                state.user = payload
        }
        // SET_AUTH(state, payload){
        //     state.user = payload
        // },

     

}