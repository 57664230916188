import http from '@/api/http'
export default {

      async setAllTypes({commit}) {
            try {
                  const response = await http.get('type_student/')
                  const result = await response.data
                  commit('SET_ALL_STUDENT_TYPES',result.data)
            } catch (error) {
                  console.log(error)
            }
           
        },
      
      async addProgram({commit, dispatch}, payload) {
            try {
                  const response = await http.post('type_student/', payload.program)
                  const result = await response.data
                  commit('ADD_STUDENT_TYPE', result.data)
                  payload.cb()
                  
            } catch (error) {
                  const err = error.response?.data.name
                  payload.cb()
                  dispatch("notification/addNotification", {
                        type: 'error',
                        message: `${err.name}`
                  }, { root: true });
                  
            }
      },  

      async updateProgram({commit}, obj) {
            try {
                  const response = await http.put(`type_student/?id=${obj.program.id}`, obj.program)
                  const result = await response.data
                  commit('UPDATE_STUDENT_TYPE', result.data)
                  obj.cb()
            } catch (error) {
                  console.log(error)
            }
      },

      async deleteProgram({commit,dispatch}, payload) {
            try {
               const response = await http.delete(`type_student/?id=${payload.id}`)
               const result = await response.data
               commit('DELETE_STUDENT_TYPE', payload.id)
               payload.cb()
            } 
            catch (error) {
                  payload.cb()
                  dispatch("notification/addNotification", {
                        type: 'error',
                        message: 'You can`t delete this program'
                  }, { root: true });
            }
        }

}