<template>
    <!-- v-if="getMyName != 'account'" -->
  <div class="inst_title" >
    <div>
      <a href="#" class="notification">
        <span>Visa</span>
        <span class="badge">{{ getAllNotifications?.visa_end }}</span>
      </a>
    </div>
    <div>
      <a href="#" class="notification">
        <span>Register</span>
        <span class="badge">{{ getAllNotifications?.register_end }}</span>
      </a>
    </div>
    <div>
      <a href="#" class="notification">
        <span>Medical</span>
        <span class="badge">{{ getAllNotifications?.medical_end }}</span>
      </a>
    </div>
    <div>
      <a href="#" class="notification">
        <span>Passport</span>
        <span class="badge">{{ getAllNotifications?.passport_end }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {};
  },

  methods: {
    ...mapActions("order_document", ["setLastDays"]),
  },

  computed: {
    ...mapGetters("order_document", ["getAllNotifications"]),
  },

  mounted() {
        this.setLastDays()

    }
};
</script>

<style scoped>
.inst_title {
  display: flex;
  margin-right: 45px;
}
.inst_title div {
  margin-right: 15px;
}

.notification {
  background-color: #555;
  color: white;
  text-decoration: none;
  min-width: 70px;
  padding: 4px 7px;
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
}

.notification .badge {
  position: absolute;
  top: -7px;
  right: -10px;
  padding: 1px 5px;
  border-radius: 50%;
  background-color: #ec7063;
  color: white;
}
.admin_title {
  font-size: 18px;
}
</style>
